<template>
    <div class="location-info-table">
      <div class="d-grid d-lg-flex justify-content-lg-end btn-save" style="margin-bottom: 1.5rem">
        <button class="btn btn-primary" type="button" :disabled="enablePDF || loadingResult " @click="promptPdf()" style="text-transform: none">
          <b-spinner small v-if="enablePDF || loadingResult || isLoading"></b-spinner><i v-else class="feather icon-download"></i><span style="padding-left: 4px;">{{$t('pdf.save_pdf')}}</span>
        </button>
      </div>


        <div class="card" :class="{'card-big-map': isBigMap}">
            <div class="card-body" :class="{'big-map-wrapper': isBigMap}">
                <div :class="classesMap">
                    <div id="map" style="width: 100%; height: 100%;"></div>
                    <div class="range-wrapper" v-if="currentCircles.length">
                      <div class="d-flex justify-content-between">
                        <button class="btn" :class="{'btn-primary': showMode === 'circles', 'btn-secondary': showMode !== 'circles'}" @click="changeMode('circles')"><img class="img-in-btn" src="/images/overlapping-circles.png" >{{ $t('geo.circles') }}</button>
                        <div class="btn-group">
                          <button type="button" class="btn" :class="{'btn-primary': showMode === 'heatmap', 'btn-secondary': showMode !== 'heatmap'}" @click="changeMode('heatmap')">
                            <img src="/images/heatmap-icon.png" class="img-in-btn">{{ $t('geo.heatmap') }}
                          </button>
                          <button type="button" class="btn dropdown-toggle dropdown-toggle-split" :disabled="showMode !== 'heatmap'" @click="isOpacityHeatmap = !isOpacityHeatmap" :class="{'btn-primary': showMode === 'heatmap', 'btn-secondary': showMode !== 'heatmap'}">
                            <span class="visually-hidden">Toggle primary</span>
                          </button>
                        </div>
                      </div>
                      <div class="form-group m-t-10" v-if="showMode === 'heatmap'" v-show="isOpacityHeatmap">
                        <label>{{ $t('geo.visibility') }}: {{ opacityHeatmap }}%</label>
                        <input :disabled="mapEdited" type="range" v-model.number="opacityHeatmap" :step="10" :min="10" :max="100">
                      </div>
                    </div>
                    <div class="big-map-switcher-wrapper">
                      <button class="btn btn-primary" for="btn-check" @click="switchSize">
                        <toggleSizeIcon color="white" class="m-r-10"></toggleSizeIcon>
                        <span>{{ !isBigMap ? $t('geo.big_map') : $t('geo.small_map') }}</span> <span class="text-lowercase">{{ $t('geo.the_map') }}</span></button>
                    </div>
                </div>
                <div v-if="isBigMap" class="big-map-spacer"></div>
                <div class="d-flex justify-content-between m-t-10" v-if="mapEdited" :class="{'big-map-buttons': isBigMap}">  
                  <b-button variant="secondary" class="lower-button" @click="closeEditing">{{ $t('geo.cancel_specify') }}</b-button>
                  <button  class="btn btn-primary m-r-10 lower-button"  v-if="enableSpecifiing" :disabled="!enableSpecifiing || specifiing" @click="sendDetailedInfo">{{ $t('geo.specify_location') }} <b-spinner small v-if="specifiing"></b-spinner></button>
                  <button class="btn btn-primary" @click="scrollToId"><img src="/images/table_feather.svg" style="width: 18px; margin-top: -3px;" alt=""> <p class="d-inline-block m-0 capitalize">{{ $t('geo.scroll_to_user') }}</p></button>
                </div>
              <div class="d-flex justify-content-end m-t-10" v-if="canBeScrollDown && !mapEdited" :class="{'big-map-buttons': isBigMap}">
                <button class="btn btn-primary" @click="scrollToId"><img src="/images/table_feather.svg" style="width: 18px; margin-top: -3px;" alt=""> <p class="d-inline-block m-0 capitalize">{{ $t('geo.scroll_to_user') }}</p></button>
              </div>
              
            </div>
        </div>
        <!-- Your content goes here -->
         <div v-if="!loadingResult && results[0].search_results && results[0].search_results.length">
             <div class="card" v-for="part in results">
                 <div class="card-body">
                    <div class="row">
                      <div :class="{'col-md-9': !meta.parent, 'col-md-12': meta.parent}">
                        <multiselect v-model="selectedColumns" :options="fields" :multiple="true" :close-on-select="false"
                            @input="sort" :clear-on-select="false" :preserve-search="true" track-by="key" selectLabel=""
                            deselectLabel="" selectedLabel="" :placeholder="$t('users_list_params.colvis')" label="label">
                            <span slot="noResult">{{ $t('user.no_result') }}</span>

                            <template slot="tag" slot-scope="{option, remove}">
                                    <span class="multiselect__tag" :class="{'notRemove':option.$isDisabled}">
                                      <span>{{option.label}}</span>
                                      <i tabindex="1" v-if="!option.$isDisabled" class="multiselect__tag-icon" @click="remove(option)"></i>
                                    </span>
                                  </template>
                        </multiselect>
                      </div>
                      <div class="col-md-3" v-if="!meta.parent">
                        <input type="text" class="form-control search-filter" style="height: 100%;" v-model="filterSearch">
                      </div>
                    </div>
                     <div class="overflow-x-scroll">
                         <table class="table table-hover">
                             <thead>
                                 <th class="text-center"><button class="round-btn" @click="openClose"><i class="feather"
                                             :class="{ 'icon-chevrons-up': showAll, 'icon-chevrons-down': !showAll }"></i></button>
                                 </th>
                                 <th>{{ $t('params.photo') | capitalize }}</th>
                                 <th v-for="column in selectedColumns">
                                     {{ column.key !== 'actions' ? column.label : '' }}
                                 </th>
                             </thead>
                             <infoItem :ref="'info-item'" v-for="result in filteredResults" @showOnMap="showOnMap" @fitBounds="fitBounds" @scrollSettings="scrollSettings" @detailizeLocation="detailizeMapLocation"
                                 :meta="meta" :columns="selectedColumns" :key="result.id" :result="result" @setCircles="setCircles" @editMap="() => closeEditing()" @setHeatmap="showMode = 'heatmap'" />
                         </table>
                     </div>
                 </div>
             </div>
         </div>
        <div class="card" v-else-if="!loadingResult">
          <div class="card-body">
            <p class="m-b-0" style="margin-bottom: 0" v-if="!results[0].error_code"><b>{{$t("result.services_returned_no_results")}}: </b>
              <span class="d-inline-block f-w-700">
                    {{ results[0].source }}
              </span>
            </p>
            <p v-else style="margin-bottom: 0">
              <span>{{$t("result.services_returned_errors")}}: </span>
              <span class="d-inline-block f-w-700">
                 {{ results[0].source }}
              </span>
            </p>
          </div>
        </div>
      <b-modal v-model="promptPdfActive" :title="$t('pdf.save_file')" has-modal-card centered hide-footer trap-focus :destroy-on-hide="false"
               aria-role="dialog" aria-label="Example Modal" aria-modal>
        <PdfPrompt v-bind="formProps" @close="closeModal" @doc-data="resultToDocument" @pdf-data="pdfUpdateData">
        </PdfPrompt>
      </b-modal>
    </div>
</template>

<script>
import L from "leaflet"
import 'leaflet/dist/leaflet.css';
import infoItem from "@/components/locations/infoItem.vue";
import _ from "underscore";
import { mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect'
import filters from "@/mixins/filters";
import drawLocales from "leaflet-draw-locales";
import savePdf from "@/mixins/savePdf";
import saveDoc from "@/mixins/saveDoc";
import imageAxios from "@/configs/iamgeAxios";
import imageMixin from "@/mixins/imageMixin";
import { isUndefined } from "underscore";
import { debounce } from "@/helpers/debounce";
import 'leaflet.heat/dist/leaflet-heat.js';
import newLocation from '@/components/searchFromResult/newLocation.vue';
import toggleSizeIcon from '@/components/icons/toggleSizeIcon.vue';

const CustomMarker = L.Icon.extend({
  options: {
    shadowUrl: null,
    iconAnchor: new L.Point(12, 41),
    iconSize: new L.Point(25, 41),
    iconUrl: '/images/marker-icon.png',
  }
});

const initMarker = L.Icon.extend({
  options: {
    shadowUrl: null,
    iconAnchor: new L.Point(12, 41),
    iconSize: new L.Point(25, 41),
    iconUrl: '/images/marker-icon-modified.png',
  }
});



export default {
  name: 'Location',
  mixins: [filters, savePdf, saveDoc, imageMixin],
  components: {
    infoItem,
    Multiselect,
    toggleSizeIcon
  },
  data() {
        return {
            url: "//{s}.tile.osm.org/{z}/{x}/{y}.png",
            zoom: 5,
            center: [55.019531, 41.71803],
            bounds: null,
            map: null,
            loadingResult: true,
            drawnItems: null,
            searchItems: null,
            selectedColumns: [
                { index: 1, key: "telegram_id", label: this.$t('params.telegram_id'), $isDisabled: true, },
                { index: 2, key: "name", label: this.$t('params.name'), $isDisabled: true, },
                { index: 3, key: "distance", label: this.$t('geo.distance'), $isDisabled: true, },
                { index: 4, key: "isdn", label: this.$t('params.isdn') },
                { index: 5, key: "username", label: this.$t('params.username') },
                { index: 6, key: "type", label: this.$t('params.type')},
                { index: 7, key: "actions", label: this.$t('params.tools'), $isDisabled: true, },
            ],
            fields: [
                { index: 1, key: "telegram_id", label: this.$t('params.telegram_id'), $isDisabled: true, },
                { index: 2, key: "name", label: this.$t('params.name'), $isDisabled: true, },
                { index: 3, key: "distance", label: this.$t('geo.distance'), $isDisabled: true, },
                { index: 4, key: "isdn", label: this.$t('params.isdn') },
                { index: 5, key: "username", label: this.$t('params.username') },
                { index: 6, key: "type", label: this.$t('params.type')},
                { index: 7, key: "actions", label: this.$t('params.tools'), $isDisabled: true, },
            ],
            showAll: false,
            mapEdited: false,
            payload: {
                location: {
                    type: 'point',
                    coordinates: null
                }
            },
            specifiing: false,
            enableSpecifiing: false,
            locale: this.$i18n.locale === 'ua' ? 'uk' : this.$i18n.locale,
            enablePDF: true,
            imageCount: 0,
            isLoading: false,
            drawControl: null,
            idScroll: null,
            canBeScrollDown: false,
            filterSearch: '',
            filteredResults: [],
            showMode: 'circles',
            currentCircles: [],
            opacityHeatmap: 60,
            isOpacityHeatmap: true,
            isBigMap: false,
            lastSelectedCircles: []
        }
    },
    computed: {
      ...mapGetters('contacts', ['results', 'meta', 'errors']),
      ...mapGetters('images', ['images', 'photoLoaded']),
      classesMap() {
        
        const classesObject = {
          'map-edited': this.mapEdited,
          'draw-disabled': !this.mapEdited || this.specifiing,
          'big-map': this.isBigMap,
          'small-map': !this.isBigMap
        }
        const classsesArray = [`opacity-heatmap-${this.opacityHeatmap}`]
        for (const key in classesObject) {
          if (classesObject[key]) {
            classsesArray.push(key)
          }
        }
        return classsesArray
      },
    },
    watch: {
      photoLoaded() {
        if (this.photoLoaded >= this.imageCount || this.imageCount === 0) {
          this.enablePDF = false;
        }
      },
      filterSearch(...args) {
        this.debouncedFilter(...args);
      },
      showMode() {
        this.clearHeatmap();
        if(this.currentCircles.length) {
          this.showOnMap(this.currentCircles);
        }
      },
      isBigMap(val) {
        setTimeout(() => {
          this.map.invalidateSize();
        }, 100)
        this.saveMapSize()
      },
      results: {
        handler(val) {
          this.resultMove = val;
        },
        deep: true
      },
      opacityHeatmap(val) {
        localStorage.setItem('opacityHeatmap', val);
      }
    },
    methods: {
        closeEditing(){
          this.mapEdited = false;
          this.map.removeControl(this.drawControl);
          this.map.addControl(this.drawControl);
          this.searchItems.clearLayers();
          this.enableSpecifiing = false;
          if (this.lastSelectedCircles.length){
            this.showOnMap(this.lastSelectedCircles)
          }
        },
        scrollSettings(item){
          this.canBeScrollDown = true;
          this.idScroll = `location_${item.telegram_id}`;
        },
        closeModal() {
          this.promptPdfActive = false;
        },
        countImages(){
          for (let result of this.results){
            if(result.search_results) {
              for (let searchResult of result.search_results){
                if (typeof  searchResult.photos !== 'undefined'){
                  for (let i = 0; i < searchResult.photos.length; i++){
                    this.imageCount++;
                  }
                }
              }
            }
          }
        },
        loadAllImages(){
          for (let result of this.results){
            if(result.search_results) {
              for (let searchResult of result.search_results){
                if (typeof  searchResult.photos !== 'undefined'){
                  for (let i = 0; i < searchResult.photos.length; i++){
                    this.loaderForImages(searchResult.photos[i].image)
                  }
                }
              }
            }
          }
        },
        async loaderForImages(payload) {
          let result = await this.checkCache(payload);
          if (result.isCached){
            this.$store.dispatch('images/addImage', {
              key: payload,
              value: ''
            });
          } else {
            let axiosSettings;
            axiosSettings = {
              method: "get",
              responseType: 'arraybuffer',
              headers: {
                'Authorization': this.token
              }
            }
            return new Promise((resolve, reject) => {
              // const imAxios = imageAxios(localStorage.getItem('token'))
              imageAxios(payload, axiosSettings)
                  .then((resp) => {
                    let blob = new Blob([resp.data], {type: "image/jpg"})
                    let reader = new window.FileReader();
                    reader.readAsDataURL(blob);
                    reader.onload = () => {
                      this.saveImage(reader.result, payload)
                      this.$store.dispatch('images/addImage', {
                        key: payload,
                        value: ''
                      });
                      resolve()
                    }
                  })
                  .catch(err => {
                    reject(err)
                  })
            })
          }
        },
        async initMap() {
            const locale = drawLocales(this.locale)
            L.drawLocal = locale;
            const center = [this.meta.payload.location.coordinates[1], this.meta.payload.location.coordinates[0]];
            this.map = await L.map('map', {
                zoomControl: false,
                attributionControl: false,
                center: this.center,
            }).setView(center, 13);
            L.control.zoom({
              zoomInTitle: this.$t('geo.zoomIn'),
              zoomOutTitle: this.$t('geo.zoomOut')
            }).addTo(this.map);

            L.tileLayer(this.url, { maxZoom: 19, minZoom: 3, openLegendOnLoad: false, attribution: '', noWrap: true }).addTo(this.map);
            var southWest = L.latLng(-89.98155760646617, -180), 
            northEast = L.latLng(89.99346179538875, 180); 
            var bounds = L.latLngBounds(southWest, northEast); 
            this.map.setMaxBounds(bounds); 
            this.drawnItems = new L.FeatureGroup().addTo(this.map);
            this.searchItems = new L.FeatureGroup().addTo(this.map);
            L.control.scale({imperial: false, position: 'bottomright'}).addTo(this.map);
            this.drawControl = new L.Control.Draw({
                edit: {
                  featureGroup: this.searchItems,
                  edit: false,
                  delete: false,
                },
                draw: {
                    circle: false,
                    polygon: false,
                    marker: {
                        icon: new CustomMarker()
                    },
                    polyline: false,
                    rectangle: false,
                    circlemarker: false
                }
            });
            this.heatmap = L.heatLayer([], { radius: 20, blur: 15, scaleRadius: true}).addTo(this.map);
            L.drawLocal.edit.toolbar.buttons.remove = this.$t('map.delete')
            L.drawLocal.edit.toolbar.buttons.removeDisabled = this.$t('map.remove_disabled')



            this.map.addControl(this.drawControl);
            this.map.on('draw:created', (event) => {
              this.searchItems.clearLayers();
              this.enableSpecifiing = true;
              this.searchItems.addLayer(event.layer);
              const layer = event.layer;
              let point = layer.toGeoJSON()
              this.payload.location.coordinates = point.geometry.coordinates;
              this.drawControl.setDrawingOptions({
                edit: {
                  featureGroup: this.searchItems,
                  edit: false,
                  delete: true,
                }
              });
            });
            this.map.on('draw:deletestart', () => {
                this.enableSpecifiing = false;
                L.drawLocal.edit.handlers.remove.tooltip.text = ''
                this.searchItems.clearLayers();
                this.map.fire('draw:deletestop');
            });
            this.map.on('draw:deletestop', () => {
              setTimeout(() => {
                document.querySelectorAll('.leaflet-draw-draw-marker')[0].click()
              }, 100)
            });
            this.map.on('zoomend', (event) => {
              if(this.showMode === 'heatmap' && this.currentCircles.length && !this.mapEdited) {
                this.setHeatmap(this.currentCircles);
              }
            })
        },
        setInitialCircle() {
            if(this.results[0].search_results && !_.isUndefined(this.results[0]) && !_.isUndefined(this.results[0].search_results[0]) && !_.isUndefined(this.results[0].search_results[0].locations[0])){
                const circleObj = this.results[0].search_results[0].locations[0];
                const marker = L.marker([circleObj.latitude, circleObj.longitude], { icon: new CustomMarker() });
                const address = !_.isUndefined(circleObj.address) ? circleObj.address : this.$t('geo.not_address');
                marker.bindTooltip(`<strong style="font-weight: 600">${this.$t('geo.center')}</strong>: ${address}<br><strong style="font-weight: 600">${this.$t('geo.radius')}</strong>: ${circleObj.distance}${this.$t('geo.meters')}`);
                this.drawnItems.addLayer(marker);
            } else {
              const circleObj = {
                latitude: this.meta.payload.location.coordinates[0],
                longitude: this.meta.payload.location.coordinates[1]
              };
              const marker = L.marker([circleObj.latitude, circleObj.longitude], { icon: new CustomMarker() });
              const address = !_.isUndefined(circleObj.address) ? circleObj.address : this.$t('geo.not_address');
              marker.bindTooltip(`<strong>${this.$t('geo.center')}</strong>: ${address}<br><strong>`);
              this.drawnItems.addLayer(marker);
            }
          this.bounds = this.drawnItems.getBounds();
          this.map.fitBounds(this.bounds);
        },
        async getResultById() {
            return new Promise((resolve) => {
                if (!_.isUndefined(this.$route.params.id)) {
                    this.loadingResult = true;
                    this.$store.dispatch('contacts/updateResultPush', parseInt(this.$route.params.id)).finally(() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        resolve();
                        this.loadingResult = false
                    })
                } else {
                    this.loadingResult = false
                    resolve()
                }
            })
        },
        sort() {
            if (this.selectedColumns.length) {
                let arr = this.selectedColumns.sort(function (a, b) { return a.index - b.index })
                this.selectedColumns = arr
            }
        },
        showOnMap(circles) {
          this.lastSelectedCircles = []
          window.scrollTo({ top: 0, left: 100, behavior: 'smooth' });
          this.currentCircles = circles;
          switch(this.showMode) {
            case 'heatmap':
              // this.setCircles(circles);
              this.setHeatmap(circles);
              break;
            case 'circles':
              this.setCircles(circles);
              break;
          }
        },
        fitBounds(circles){
          this.setCircles(circles)
          this.bounds = this.drawnItems.getBounds();
          this.map.fitBounds(this.bounds);
          this.showOnMap(circles)
        },
        setCircles(circles) {
          this.clearHeatmap();
            this.drawnItems.clearLayers();
            for (const circleObj of circles) {
                if(isUndefined(circleObj.latitude) || isUndefined(circleObj.longitude) || isUndefined(circleObj.distance)) continue;
                const circle = L.circle([circleObj.latitude, circleObj.longitude], {
                    color: '#3572EF',
                    fillColor: '#3572EF',
                    fillOpacity: 0.5,
                    radius: parseInt(circleObj.distance)
                });
                this.drawnItems.addLayer(circle);
            }

            // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            this.setPins(circles);
        },
        setHeatmap(circles) {
          const pointsArray = [];
            this.drawnItems.clearLayers();
            const zoomLevel = this.map.getZoom();
            const scale = this.getScale(zoomLevel, this.map.getCenter().lat);
            const countDefault = 25;
            for (const circleObj of circles) {
              if(circleObj.distance > 20000) continue;
              const radiusCoef = (countDefault/500)*(circleObj.distance * circleObj.distance);
              const count = 1/scale * (radiusCoef/5.629124503608034);
              if(isUndefined(circleObj.latitude) || isUndefined(circleObj.longitude) || isUndefined(circleObj.distance)) continue;
              const points = this.generateUniformPoints(circleObj.latitude, circleObj.longitude, circleObj.distance, parseInt(count));
              pointsArray.push(...points);
            }
            this.heatmap.setLatLngs(pointsArray);
        },
        clearHeatmap() {
            this.heatmap.setLatLngs([]);
        },
        generateUniformPoints(centerLat, centerLng, radius, count) {
            var points = [];
            var angleStep = (Math.PI * (3 - Math.sqrt(5))); // золоте перетинання для рівномірного розподілу

            for (var i = 0; i < count; i++) {
                var r = Math.sqrt(i / count) * radius;
                var angle = i * angleStep;

                var lat = centerLat + (r * Math.cos(angle)) / 111320; // 1 degree latitude = ~111.32 km
                var lng = centerLng + (r * Math.sin(angle)) / (111320 * Math.cos(centerLat * Math.PI / 180));
                points.push([lat, lng]);
            }
            return points;
        },
        getScale(zoomLevel, latitude) {
            // Постійна для обчислення масштабу
            var equatorLength = 40075016.686; // Довжина екватора в метрах
            var scale = equatorLength * Math.cos(latitude * Math.PI / 180) / Math.pow(2, zoomLevel + 8);
            return scale;
        },
        setPins(circles) {
          // let initPoint = JSON.parse(JSON.stringify(this.meta.payload.location.coordinates)).reverse()
          let svgTemplate = `<div class="initMarker"></div>`;
          let icon = L.divIcon({
            className: "init-marker bounce-3",
            html: svgTemplate,
            iconSize: [40, 40],
            iconAnchor: [20, 40],
            popupAnchor: [0, -30]
          });
          const arrayIndexes  = {};
          Object.keys(_.groupBy(circles, 'info_date')).forEach((item,index) => {
            arrayIndexes[item] = index + 1
          });
          for (const circleObj of circles) {
                if(isUndefined(circleObj.latitude) || isUndefined(circleObj.longitude)) continue;
                let marker;
                const address = !_.isUndefined(circleObj.address) ? circleObj.address : this.$t('geo.not_address');
                if (this.meta.payload.location.coordinates[1] === circleObj.latitude && this.meta.payload.location.coordinates[0] === circleObj.longitude){
                  marker = L.marker([circleObj.latitude, circleObj.longitude], {
                    icon: icon
                  });
                  marker.bindTooltip(`<strong>${this.$t('geo.search_start')}</strong> <br/> <strong>${this.$t('geo.center')}</strong>: ${address}<br><strong>${this.$t('geo.radius')}</strong>: ${circleObj.distance}${this.$t('geo.meters')}`);

                } else {
                  let hintText = '';
                  if(this.meta.parent) {
                    hintText += `<strong>${this.$t('geo.estimated_geolocation')} #${arrayIndexes[circleObj.info_date]}</strong> </br>  `;
                  }
                  hintText += `<strong>${this.$t('geo.center')}</strong>: ${address}<br><strong>${this.$t('geo.radius')}</strong>: ${circleObj.distance}${this.$t('geo.meters')}`;
                  marker = L.marker([circleObj.latitude, circleObj.longitude], { icon: new CustomMarker() });
                  marker.bindTooltip(hintText);

                }
                this.drawnItems.addLayer(marker);
            }
        },
        openClose() {
            this.showAll = !this.showAll
            if (this.showAll) {
                this.$refs['info-item'].forEach(item => item.openDetails())
            } else {
                this.$refs['info-item'].forEach(item => item.closeDetails())
            }
        },
        detailizeMapLocation({resultObj, locations}) {
            this.clearHeatmap();
            this.mapEdited = true;
            this.currentCircles = [];
            this.lastSelectedCircles = [];
            this.idScroll = `location_${resultObj.telegram_id}`;
            document.querySelectorAll('.leaflet-draw-draw-marker')[0].click()
            this.payload.additional_options = {telegram_id: parseInt(resultObj.telegram_id)};
            this.drawnItems.clearLayers();
            this.searchItems.clearLayers();
            this.setCircles(locations);
            this.bounds = this.drawnItems.getBounds();
            this.map.fitBounds(this.bounds);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            this.lastSelectedCircles = locations;
        },
        sendDetailedInfo() {
            this.specifiing = true;
            const services = JSON.parse(localStorage.getItem('services'));
            const service = _.findWhere(services, {name: "TelegramGeo"});
            const toastText = !this.meta.parent ? newLocation : this.$t("geo.detail_toast");
            this.searchItems.clearLayers();
            this.$store.dispatch('contacts/detalizeLocation', {payload: this.payload, service_id: service.id})
                .then((data) => {
                    this.specifiing = false;
                    this.mapEdited = false;
                    this.enableSpecifiing = false;
                    
                    if(!this.meta.parent) {
                        this.$store.dispatch('contacts/cacheWithResultsChild', {
                            data: data.results,
                            parentKey: this.meta.key,
                            payload: this.payload,
                            type: "detalizeLocation"
                        })
                    } else {
                        this.$store.dispatch('contacts/cacheSpecifiedResults', {
                            id: parseInt(this.$route.params.id),
                            results: data.results,
                        }).then(() => {
                          this.debouncedFilter(this.filterSearch, '');
                          this.setCircles(this.results[0].search_results[0].locations);
                        })
                    }
                    this.$toast.success(toastText, {
                        position: "top-center",
                        timeout: 5000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        rtl: false
                    });
                    if (this.lastSelectedCircles.length){
                      this.showOnMap(this.lastSelectedCircles)
                    }
                })
        },
        scrollToId() {
            if(this.idScroll) {
              // if (this.canBeScrollDown){
              //   this.canBeScrollDown = false;
              // }
                const el = document.getElementById(this.idScroll);
                if(el) {
                    el.scrollIntoView({behavior: "smooth", block: "center"});
                    el.classList.add('highlight');
                    setTimeout(() => {
                        el.classList.remove('highlight');
                    }, 2000)
                }
            }
        },
        changeMode(mode) {
          this.showMode = mode;
        },
        setOpacityHeatmap() {
          if(localStorage.getItem('opacityHeatmap')) {
            this.opacityHeatmap = parseInt(localStorage.getItem('opacityHeatmap'));
          }
        },
        switchSize() {
          this.isBigMap = !this.isBigMap;
          if(this.isBigMap) {
            this.closeMenu();
          } else {
            this.openMenu();
          }
          setTimeout(() => {
            this.map.invalidateSize();
          }, 100)
        },
        closeMenu() {
          const isOpen = document.getElementById('pcoded').attributes['vertical-nav-type'].value === 'expanded';
          if(isOpen) {
            document.querySelector('#mobile-collapse').click();
          }
        },
        openMenu() {
          const isOpen = document.getElementById('pcoded').attributes['vertical-nav-type'].value === 'expanded';
          if(!isOpen) {
            document.querySelector('#mobile-collapse').click();
          }
        },
        setMapSize() {
          if(localStorage.getItem('isBigMap')) {
            const isTrue = localStorage.getItem('isBigMap') === 'true'
            this.isBigMap = isTrue;
          }
          setTimeout(() => {
            if(this.isBigMap) {
              this.closeMenu();
              this.map.invalidateSize();
            }
          }, 100)
        },
        saveMapSize() {
          localStorage.setItem('isBigMap', this.isBigMap);
        },
    },
    created(){
      this.debouncedFilter = debounce((newValue, oldValue)=>{
        this.filteredResults = this.results[0].search_results.filter((result) => {
          return result.data.some(({value}) => {
            return String(value).toLowerCase().includes(newValue.toLowerCase());
          });
        });
     }, 200);
  },

    async mounted() {
        await this.setMapSize();
        await this.getResultById();
        await this.initMap();
        await this.setOpacityHeatmap();
        if(this.results[0].search_results && this.results[0].search_results.length) {
          this.filteredResults = this.results[0].search_results;
            if(!this.meta.parent) {
                await this.setInitialCircle();
            } else {
                await this.showOnMap(this.results[0].search_results[0].locations);
            }
        }
        await this.setMountedPdfAction();
        await this.mountActionsDoc();
        await this.countImages();
        await this.loadAllImages();
        await this.$store.dispatch('images/clearImages');
        await this.$store.dispatch('contacts/forceUpdateSearchParams');
    },
}
</script>

<style>
.location-info-table .table td {
    padding: 8px;
}

.location-info-table .leaflet-tooltip-pane>* {
    width: 320px !important;
    white-space: normal !important;
}

.round-btn {
    border-radius: 50%;
    background-color: transparent;
    border: none;
    transition: background-color 0.3s;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.round-btn i {
    height: 15px;
    width: 15px;
    margin: 0;
}

.round-btn:hover {
    background-color: #f5f5f5;
}
@media (max-width: 1300px) {
    .location-info-table .overflow-x-scroll {
        overflow-x: scroll;
    }
}

.multiselect.multiselect--above {
    z-index: 1001;
}
.notRemove{
  padding-right: 10px;
}

.map-edited {
    border: 2px dashed #3572EF;
}

.draw-disabled .leaflet-draw.leaflet-control {
    display: none;
}

.draw-disabled .leaflet-draw-draw-marker {
    cursor: default;
    pointer-events: none;
}

.draw-disabled .leaflet-draw-draw-marker::before {
    position: absolute;
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
}

.map-edited .leaflet-draw-draw-marker {
    cursor: pointer;
}

.highlight {
    background-color: rgba(64, 153, 255, 0.1);
    transition: background-color 0.5s;
}

body .btn-outline-primary, body .btn-outline-primary:hover {
  background-color: #fff;
}
body .btn-check + .btn:hover {
  background: #fff;
}

/* body .btn-check + .btn.btn-outline-primary:hover {
  
} */
.btn-check:checked + .btn:hover {
  background: #73b4ff;
  color: #fff;
}

.form-group input[type="range"] {
  accent-color: #4099ff;
  width: 100%;
}

.range-wrapper {
  padding: 10px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 999;
  width: 350px;
}

@media (max-width: 767px) {
  .range-wrapper {
    position: relative;
    box-shadow: none;
    top: auto;
    right: auto;
    background: transparent;
    width: 100%;
  }
}

/* .range-wrapper .btn-group {
 width: 100%;
} */

.range-wrapper .form-group {
  margin-bottom: 0;
  width: 100%;
}

.opacity-heatmap-10 .leaflet-heatmap-layer {
  opacity: 0.1;
}

.opacity-heatmap-20 .leaflet-heatmap-layer {
  opacity: 0.2;
}

.opacity-heatmap-30 .leaflet-heatmap-layer {
  opacity: 0.3;
}

.opacity-heatmap-40 .leaflet-heatmap-layer {
  opacity: 0.4;
}

.opacity-heatmap-50 .leaflet-heatmap-layer {
  opacity: 0.5;
}

.opacity-heatmap-60 .leaflet-heatmap-layer {
  opacity: 0.6;
}

.opacity-heatmap-70 .leaflet-heatmap-layer {
  opacity: 0.7;
}

.opacity-heatmap-80 .leaflet-heatmap-layer {
  opacity: 0.8;
}

.opacity-heatmap-90 .leaflet-heatmap-layer {
  opacity: 0.9;
}
.init-marker {
  background: transparent;
  border: none;
  cursor: pointer;
  display: block;
  height: 80px;
  width: 80px;
  text-align: center;
}
.initMarker {
  /*margin-left: 1px;*/
  margin-left: 8px;
  position: relative;
  width: 38px;
  height: 40px;
  /*background: url(../../public/images/pin.png) no-repeat;*/
  background: url(../../public/images/red-icon.png) no-repeat;
  background-size: contain;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: black;
  -webkit-animation: action 1s infinite  alternate;
  animation: action 1s infinite  alternate;
}
@-webkit-keyframes action {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}

@keyframes action {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}

.btn-outline-primary  {
  --bs-btn-color: rgb(64, 153, 255);
  --bs-btn-border-color: rgb(64, 153, 255);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgb(64, 153, 255);
  --bs-btn-hover-border-color: rgb(64, 153, 255);
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgb(64, 153, 255);
  --bs-btn-active-border-color: rgb(64, 153, 255);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: rgb(64, 153, 255);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: rgb(64, 153, 255);
}



.mapTypeIcon{
  width: 22px;
  height: 22px;
  margin-right: 8px;
}
.circles{
  background:center / contain no-repeat url(../../public/images/circles-icon.png);
}
.heatmap{
  background: center / contain no-repeat url(../../public/images/heat-map.png);
}

.big-map {
  position: absolute;
  left: 0;
  right: 0;
  height: calc(100vh - 300px);
  z-index: 100;
}
.small-map {
  height: 400px;
}
.big-map-wrapper {
  height: calc(100vh - 250px);
  padding-top: 0;
}
.card-big-map {
  position: static;
}
.img-in-btn {
  width: 24px;
  margin-right: 10px;
  display: inline-block;
}
.big-map-switcher-wrapper {
  position: absolute;
  top: 375px;
  left: 26px;
  z-index: 1000;
}
.big-map-wrapper .big-map-switcher-wrapper {
  bottom: 5px;
  left: 10px;
  top: auto;
}
.big-map-spacer {
  height: calc(100vh - 300px);
}
.big-map-buttons {
  position: relative;
  z-index: 1000;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(43, 43, 43, 0.1), 0 11px 6px -7px rgba(43, 43, 43, 0.1);
  padding: 10px 15px;
  background-color: #fff;
}
.card.card-big-map {
  background-color: transparent;
  box-shadow: none;
  margin-right: -15px;
  margin-left: -15px;
}
</style>