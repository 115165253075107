<template>
    <div>
        <VueSlickCarousel v-bind="settings" @click.prevent>
            <div v-for="(photo, k) in photos" :key="k">
                <photo-fragment :meta="meta" :photo="photo" :is-child="false" />
            </div>
        </VueSlickCarousel>
    </div>
</template>

<script>
import PhotoFragment from "../PhotoFragment";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
    name: 'Gallery',
    props: ['photos', 'meta'],
    components: {
        PhotoFragment,
        VueSlickCarousel
    },
    data() {
        return {
            settings: {
                "dots": true,
                "infinite": false,
                "speed": 500,
                "slidesToShow": 6,
                "slidesToScroll": 6,
                "initialSlide": 0,
                "responsive": [
                    {
                        "breakpoint": 1200,
                        "settings": {
                            "slidesToShow": 2,
                            "slidesToScroll": 2,
                            "infinite": true,
                            "dots": true
                        }
                    },
                    {
                        "breakpoint": 600,
                        "settings": {
                            "slidesToShow": 2,
                            "slidesToScroll": 2,
                            "initialSlide": 2,
                            "dots": false
                        }
                    },
                    {
                        "breakpoint": 480,
                        "settings": {
                            "slidesToShow": 1,
                            "slidesToScroll": 1,
                            "dots": false
                        }
                    }
                ]
            },
            widthWindow: 0
        }
    },
}
</script>

<style scoped>
/* Your component's CSS styles go here */
</style>