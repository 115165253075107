<template>
    <span class="icon-holder">
        <img src="/images/zoom.png" alt="">
    </span>
</template>
<script>
    export default {
        name: "ToggleSizeIcon",
        props: {
            color: {
                type: String,
                default: 'currentColor'
            }
        }
    }
</script>

<style>
    .icon-holder {
        display: inline-block;
    }
    .icon-holder img {
        width: 15px;
        height: 15px;
    }
</style>